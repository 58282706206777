import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import SEO from "../components/seo"
import newsletterData from "../../data/newsletters"
import newsletterLogo from "../../content/assets/newsletter-logo.png"

class NewsletterPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={"Newsletter"}>
        <SEO title="Peter Elbaum | Newsletter" />
        <h1 style={{ textAlign: 'center' }}><img style={{ height: '100px' }} src={newsletterLogo}/></h1>
        <p>Every month I write an email newsletter with updates from my path to sustainable self-employment, plus links to my new articles, tweets, videos and other favorites. I'd love for you to <Link to="/subscribe">join</Link>!</p>
        <h2 style={ { marginTop: '1.5rem' } }>Past Issues</h2>
        <ul>
          {
            newsletterData.newsletters.map((post) => {
              return <li><a rel="noopener noreferrer" target="_blank" href={post.link}>#{post.number}: {post.title}</a></li>
            })
          }
        </ul>
      </Layout>
    )
  }
}

export default NewsletterPage