const data = {
  newsletters: [
    {
      title: 'Drinking the AI Kool-Aid 🥤',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-100-drinking-the-ai-kool-aid',
      date: 'July 02, 2023',
      number: 100,
    },
    {
      title: 'The Weight of Self-Expectation 🏋️‍♀️',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-99-the-weight-of-self-expectation',
      date: 'March 05, 2023',
      number: 99,
    },
    {
      title: 'The Law of Your Very Self 📖',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-98-the-law-of-your-very-self',
      date: 'February 06, 2023',
      number: 98,
    },
    {
      title: 'My Year in Review 🗓',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-97-my-year-in-review',
      date: 'January 08, 2023',
      number: 97,
    },
    {
      title: 'Going Pro 💪',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-96-going-pro',
      date: 'November 06, 2022',
      number: 96,
    },
    {
      title: 'Work and Escape 💨',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-95-work-and-escape',
      date: 'October 02, 2022',
      number: 95,
    },
    {
      title: 'Finding the Right Mix 💿',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-94-finding-the-right-mix',
      date: 'September 05, 2022',
      number: 94,
    },
    {
      title: 'Work, Discovery and Curiosity 💭',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-93-work-discovery-and-curiosity',
      date: 'July 07, 2022',
      number: 93,
    },
    {
      title: 'Did I Go Back to a Full-Time Job? 🧐',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-92-did-i-go-back-to-a-full-time-job',
      date: 'May 08, 2022',
      number: 92,
    },
    {
      title: 'Working Lifestyle First 🪓',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-91-working-lifestyle-first',
      date: 'April 03, 2022',
      number: 91,
    },
    {
      title: 'Priorities and Podcasts 🎙',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-90-priorities-and-podcasts',
      date: 'March 13, 2022',
      number: 90,
    },
    {
      title: 'Prepping for Self-Employment 👷‍♂️',
      link: 'https://peterelbaum.ck.page/posts/elbaum-s-world-89-prepping-for-self-employment',
      date: 'February 06, 2022',
      number: 89,
    },
    {
      title: 'I Quit My Job ✌️',
      link: 'https://ckarchive.com/b/d0ueh0hxe9mg',
      date: 'January 23, 2022',
      number: 88,
    },
    {
      title: 'The Clarity of Regret 👴🏻',
      link: 'https://ckarchive.com/b/r8u8hohoov36',
      date: 'December 26, 2021',
      number: 87,
    },
    {
      title: 'Dream or Die Trying 💭',
      link: 'https://ckarchive.com/b/wvu2hghl26ez',
      date: 'December 19, 2021',
      number: 86,
    },
    {
      title: 'Hunters vs Farmers 🧑‍🌾',
      link: 'https://ckarchive.com/b/d0ueh0h265e3',
      date: 'December 12, 2021',
      number: 85,
    },
    {
      title: 'Against the Grain 🌾',
      link: 'https://ckarchive.com/b/k0umh6ho49lr',
      date: 'December 05, 2021',
      number: 84,
    },
    {
      title: 'Learning from Novices 🤓',
      link: 'https://ckarchive.com/b/lmuehmhd3e7k',
      date: 'November 28, 2021',
      number: 83,
    },
    {
      title: `Tim Ferriss' Prescience 🔮`,
      link: 'https://ckarchive.com/b/e5uph7hdw3d3p',
      date: 'November 21, 2021',
      number: 82,
    },
    {
      title: 'Discovering Your True Preferences 🍦',
      link: 'https://ckarchive.com/b/d0ueh0h240wn',
      date: 'November 14, 2021',
      number: 81,
    },
    {
      title: 'What Entrepreneurship and Hollywood Have In Common 💡',
      link: 'https://ckarchive.com/b/0vuwh9hl49mv',
      date: 'November 07, 2021',
      number: 80,
    },
    {
      title: 'The Price of Success 💸',
      link: 'https://ckarchive.com/b/o8ukhqhqm2dp',
      date: 'October 31, 2021',
      number: 79,
    },
    {
      title: 'The Formula for Engagement 👀',
      link: 'https://ckarchive.com/b/xmuph6hql05p',
      date: 'October 24, 2021',
      number: 78,
    },
    {
      title: 'What Does "Success" on YouTube Look Like? 🎥',
      link: 'https://ckarchive.com/b/75u7h8hn9vlv',
      date: 'October 10, 2021',
      number: 77,
    },
    {
      title: 'The Fragility of Virtual Workplace Bonds 💔',
      link: 'https://ckarchive.com/b/xmuph6hql05p',
      date: 'October 03, 2021',
      number: 76,
    },
    {
      title: 'What Makes a Good Manager? 👔',
      link: 'https://ckarchive.com/b/27u2hohm873l',
      date: 'September 26, 2021',
      number: 75,
    },
    {
      title: 'Analyzing the Overemployment Trend 🥸',
      link: 'https://ckarchive.com/b/zlughnhqg9oe',
      date: 'September 19, 2021',
      number: 74,
    },
    {
      title: 'The Secret Formula for Happiness 😊',
      link: 'https://ckarchive.com/b/5quvh7hr65qp',
      date: 'September 13, 2021',
      number: 73,
    },
    {
      title: 'Seeing What We Want 👀',
      link: 'https://ckarchive.com/b/p9ueh9hno00q',
      date: 'September 05, 2021',
      number: 72,
    },
    {
      title: 'Walking Work vs Skating Work ⛸',
      link: 'https://ckarchive.com/b/p9ueh9hng850',
      date: 'August 22, 2021',
      number: 71,
    },
    {
      title: 'The Nature of Innovation 💡',
      link: 'https://ckarchive.com/b/o8ukhqh24p7p',
      date: 'August 15, 2021',
      number: 70,
    },
    {
      title: 'Knowledge Work and Sprinting 🏃‍♂️',
      link: 'https://ckarchive.com/b/gkunh5h4o54z',
      date: 'August 08, 2021',
      number: 69,
    },
    {
      title: 'You Have to Go First 👯‍♀️',
      link: 'https://ckarchive.com/b/8kuqhohm0672',
      date: 'August 01, 2021',
      number: 68,
    },
    {
      title: 'Charlie Munger and Inversion 🔃',
      link: 'https://ckarchive.com/b/xmuph6hvm5p7',
      date: 'July 25, 2021',
      number: 67,
    },
    {
      title: 'Near-Death Experience and the Hedonic Treadmill 🏃‍♂️',
      link: 'https://ckarchive.com/b/e5uph7h38r4r',
      date: 'July 18, 2021',
      number: 66,
    },
    {
      title: 'The Curse of Knowledge 🧠',
      link: 'https://ckarchive.com/b/gkunh5h8w04l',
      date: 'July 11, 2021',
      number: 65,
    },
    {
      title: 'Climbing the Right Hill 🚵‍♂️',
      link: 'https://ckarchive.com/b/38uphkh506mq',
      date: 'July 04, 2021',
      number: 64,
    },
    {
      title: 'The 90/10 Rule and the Third Door 🚪',
      link: 'https://ckarchive.com/b/o8ukhqhmgz78',
      date: 'June 27, 2021',
      number: 63,
    },
    {
      title: 'What to Learn from "The Great Resignation"? ✌️',
      link: 'https://ckarchive.com/b/gkunh5h8eq2e',
      date: 'June 20, 2021',
      number: 62,
    },
    {
      title: 'The Slump Breaker ⚽️',
      link: 'https://ckarchive.com/b/4zuvhehdqdl6',
      date: 'June 13, 2021',
      number: 61,
    },
    {
      title: 'The Beauty of Seasonal Work 🔁',
      link: 'https://ckarchive.com/b/4zuvhehd5e2x',
      date: 'June 06, 2021',
      number: 60,
    },
    {
      title: 'Why Do Immigrants Become Entrepreneurs? 💪',
      link: 'https://ckarchive.com/b/qdu8h7h53zx8',
      date: 'May 30, 2021',
      number: 59,
    },
    {
      title: 'What Happens When You Reach a Lifelong Goal? 🥇',
      link: 'https://ckarchive.com/b/n4uohvhpzvnl',
      date: 'May 23, 2021',
      number: 58,
    },
    {
      title: 'The Boundary of Your Stuff 📚',
      link: 'https://ckarchive.com/b/gkunh5h5wke4',
      date: 'May 16, 2021',
      number: 57,
    },
    {
      title: 'Your Most Valuable Client 👈',
      link: 'https://ckarchive.com/b/mvu7h5hp2n26',
      date: 'May 10, 2021',
      number: 56,
    },
    {
      title: 'My Changing Investment Philosophy 💸',
      link: 'https://ckarchive.com/b/92uzhnhmdenl',
      date: 'May 02, 2021',
      number: 55,
    },
    {
      title: 'Work For Its Own Sake 📝',
      link: 'https://ckarchive.com/b/o8ukhqhv8999',
      date: 'April 25, 2021',
      number: 54,
    },
    {
      title: 'Fixing and Self-Reliance 🪚',
      link: 'https://ckarchive.com/b/k0umh6hqe4kp',
      date: 'April 18, 2021',
      number: 53,
    },
    {
      title: 'Reflecting on a Year of Newsletters ✍️',
      link: 'https://ckarchive.com/b/qdu8h7h5gok8',
      date: 'April 12, 2021',
      number: 52,
    },
    {
      title: 'Why I Hired a Video Editor 🎥',
      link: 'https://ckarchive.com/b/r8u8hohzomrq',
      date: 'April 04, 2021',
      number: 51,
    },
    {
      title: 'The Three Kinds of Leverage 📐',
      link: 'https://ckarchive.com/b/r8u8hohzlm4l',
      date: 'March 28, 2021',
      number: 50,
    },
    {
      title: `The Story of John Grisham's Persistence 📚 💪 ⚖️`,
      link: 'https://ckarchive.com/b/p9ueh9ho673d',
      date: 'March 21, 2021',
      number: 49,
    },
    {
      title: 'The Genius of One Bite Pizza Reviews 🍕',
      link: 'https://ckarchive.com/b/wvu2hgh684xp',
      date: 'March 14, 2021',
      number: 48,
    },
    {
      title: 'Motion, Action, and Doing the Real Thing 🏋️‍♀️',
      link: 'https://ckarchive.com/b/lmuehmh7lnrp',
      date: 'March 07, 2021',
      number: 47,
    },
    {
      title: 'Why Kevin from The Office made $1M on Cameo 🤑',
      link: 'https://ckarchive.com/b/p9ueh9ho7k95',
      date: 'February 28, 2021',
      number: 46,
    },
    {
      title: 'The Portfolio Approach to Work 🎨',
      link: 'https://ckarchive.com/b/n4uohvh0x7x3',
      date: 'February 21, 2021',
      number: 45,
    },
    {
      title: `The Internet's Magic for Connection ✨`,
      link: 'https://ckarchive.com/b/75u7h8hlgx8v',
      date: 'February 14, 2021',
      number: 44,
    },
    {
      title: `Alamo Drafthouse's Unreal Customer Experience 🍿`,
      link: 'https://ckarchive.com/b/75u7h8hln2d5',
      date: 'February 07, 2021',
      number: 43,
    },
    {
      title: `Airrack's Rules for Cold Outreach 🤝`,
      link: 'https://ckarchive.com/b/p9ueh9h6063g',
      date: 'January 31, 2021',
      number: 42,
    },
    {
      title: 'Is It Good to Be the Best? 💪',
      link: 'https://ckarchive.com/b/p9ueh9h6mgw5',
      date: 'January 24, 2021',
      number: 41,
    },
    {
      title: 'Unmasking Conventional Wisdom 🎭',
      link: 'https://ckarchive.com/b/lmuehmh2rr6w',
      date: 'January 17, 2021',
      number: 40,
    },
    {
      title: 'Work as Hobby',
      link: 'https://ckarchive.com/b/xmuph6h2w386',
      date: 'January 10, 2021',
      number: 39,
    },
    {
      title: 'Scott Adams and Specific Knowledge',
      link: 'https://ckarchive.com/b/lmuehmh2ze9g',
      date: 'January 03, 2021',
      number: 38,
    },
  ],
};

export default data